export const APPS = {
  pt: {
    appName: "Rádio Tuga",
    path: "/portugal",
  },
  fr: {
    appName: "Radio France",
    path: "/france",
  },
  br: {
    appName: "Rádio Brasil",
    path: "/brasil",
  },
  de: {
    appName: "Radio Germany",
    path: "/germany",
  },
};
