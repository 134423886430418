import React from "react";
import styles from "./app-logo.module.css";
import Image from "../components/image";
import { APPS } from "./config";

export const AppLogo = ({ countryCode }) => {
  return (
    <a href={APPS[countryCode].path} key={countryCode} className={styles.logo}>
      <Image countryCode={countryCode} />
    </a>
  );
};
