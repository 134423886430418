import PropTypes from "prop-types";
import React from "react";
import { AppLogo } from "./app-logo";

const Header = ({ siteTitle, countryCode, logoMaxWidth = 320 }) => (
  <header>
    <div
      className="px-24 lg:px-8 py-8 md:py-16 max-w-sm mx-auto"
      style={{ maxWidth: logoMaxWidth }}
    >
      <AppLogo countryCode={countryCode} />
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
